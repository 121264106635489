<template>
  <div class="image-container">
    <div v-if="frontView" class="image-row">
      <div class="col1">
        <img :src="imageUrl.url" alt="Car Dimensions" />
        <div class="remove-top-m">
          <div class="width-line" name="width">
            <div class="line-edge"></div>
          </div>
          <label for="width">{{ dimensions.width }} mm</label>
        </div>
      </div>
      <div class="car-height col2">
        <div class="height-line" name="height">
          <div class="height-line-edge"></div>
        </div>
      </div>
      <div class="col3 remove-top-m">
        <p class="height-number">{{ dimensions.height }} mm</p>
      </div>
    </div>
    <div v-else>
      <img :src="imageUrl.url" alt="Car Dimensions" />
      <div class="remove-top-m">
        <div class="length-line" name="length">
          <div class="line-edge"></div>
        </div>
        <label for="length">{{ dimensions.length }} mm</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DimensionImage",
  props: {
    imageUrl: { type: Object },
    dimensions: { type: Object },
    frontView: { type: Boolean },
  },
  methods: {
    getImgUrl(url) {
      return require("/src/assets" + url);
    },
  },
};
</script>

<style lang="scss">
@import "/DimensionImage";
</style>
