<template>
  <div>
    <img
      :src="currImg.url"
      class="threesixty-image"
      draggable="false"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
      @mouseleave="handleMouseLeave"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
    />
  </div>
</template>

<script>
export default {
  name: "ThreeSixtyImage",
  data() {
    return {
      modelImgs: [],
      maxLenght: 35,
      currImg: "",
      num: 0,
      isMoving: false,
      speed: 15,
      savedPositionX: 0,
      currentPositionX: 0,
    };
  },
  props: {
    imgs: { type: Array },
  },
  created() {
    this.imgs.forEach((imag) => {
      this.modelImgs.push(imag);
    });
    this.currImg = this.modelImgs[3];
  },
  methods: {
    handleMouseDown(e) {
      this.isMoving = true;
      this.savedPositionX = e.pageX;
    },
    handleMouseUp() {
      this.isMoving = false;
    },
    stopRotation() {
      this.isMoving = !this.isMoving;
    },
    handleMouseLeave() {
      if (this.isMoving) {
        this.isMoving = false;
      }
    },
    handleMouseMove(e) {
      this.cfgGet360Modal(e.pageX);
    },
    handleTouchStart(e) {
      // e.preventDefault();
      this.savedPositionX = e.touches[0].pageX;
      this.isMoving = true;
    },
    handleTouchMove(e) {
      // e.preventDefault();
      this.cfgGet360Modal(e.touches[0].pageX);
    },
    cfgGet360Modal(currentPosition) {
      if (this.isMoving && this.maxLenght > 1) {
        this.currentPositionX = currentPosition;
        const distance = this.currentPositionX - this.savedPositionX;
        if (Math.abs(distance) > this.speed) {
          this.savedPositionX = this.currentPositionX;
          if (
            (distance > 0 && !this.reverse) ||
            (distance < 0 && this.reverse)
          ) {
            this.slideToRight();
          } else {
            this.slideToLeft();
          }
        }
      }
    },
    slideToRight() {
      if (this.num == this.maxLenght) {
        this.currImg = this.modelImgs[0];
        this.num = 0;
      } else {
        this.num = this.num + 1;
        this.currImg = this.modelImgs[this.num];
      }
    },
    slideToLeft() {
      if (this.num == 0) {
        this.currImg = this.modelImgs[this.maxLenght];
        this.num = this.maxLenght;
      } else {
        this.num = this.num - 1;
        this.currImg = this.modelImgs[this.num];
      }
    },
    select(url) {
      this.modelImgs.forEach((img, i) => {
        if ((img = url)) {
          this.currImg = img;
          this.num = i;
        }
      });
    },
    getImgUrl(url) {
      return require("/src/assets" + url);
    },
    change(imgs) {
      this.modelImgs = imgs;
      this.currImg = this.modelImgs[this.num];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "/ThreeSixtyImage";
</style>
