<template>
  <div>
    <div class="red-line-variant"></div>
    <div v-if="variantArray.length > 0" class="other-color">
      <span>Outras cores disponíveis</span>
    </div>
    <div class="color-variant-container">
      <div class="image-section" v-for="(variant, i) in variantArray" :key="i">
        <img
          @click="$emit('variantClicked', variant.vin)"
          class="img"
          :src="variant.imageUrl ? variant.imageUrl : placeHolder"
          alt="a"
        />
      </div>
    </div>
    <p
      v-if="this.data.length > 4"
      class="show-more-button align-left"
      @click="isShowMore = !isShowMore"
    >
      <span v-if="!isShowMore">Ver mais cores</span>
      <span v-else>Mostrar menos</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ColorVariants",
  props: {
    data: { type: Array },
  },
  components: {},
  computed: {
    variantArray() {
      let array = [];
      if (this.isShowMore) {
        array = JSON.parse(JSON.stringify(this.data));
      } else {
        if (this.data.length > 4) {
          array = JSON.parse(
            JSON.stringify([
              this.data[0],
              this.data[1],
              this.data[2],
              this.data[3],
            ])
          );
        } else {
          array = JSON.parse(JSON.stringify(this.data));
        }
      }
      return array;
    },
    placeHolder() {
      return this.$store.state.isLexus
        ? require("@/assets/unavailable-lexus.png")
        : require("@/assets/unavailable-toyota.png");
    },
  },
  methods: {},
  data: () => ({
    isShowMore: false,
  }),
};
</script>

<style lang="scss" scoped>
@import "/Range";
</style>
