<template>
  <div class="wrapper" v-if="isLoaded">
    <div class="fluid-element">
      <div class="hero-grid">
        <div class="left-slot">
          <h2 class="hero-available" v-if="newCarInfo.isAvailable">
            <span>DISPONÍVEL EM STOCK</span>
          </h2>
          <h2 v-else class="hero-unavailable">
            <span>SOB ENCOMENDA</span>
          </h2>

          <div class="main-image">
            <div
              @click="toogleFavorite"
              :class="isFavorite ? 'favorite-range on' : 'favorite-range'"
            >
              <div
                :class="
                  isFavorite
                    ? 'favorite-button-range on'
                    : 'favorite-button-range'
                "
              >
                <Heart />
              </div>
            </div>
            <div
              @click="isExterior = true"
              :class="
                isExterior
                  ? 'image-button-wrapper-exterior'
                  : 'image-button-wrapper-exterior isBehind'
              "
            >
              <img src="@/assets/Cars.svg" />
              <span>Exterior</span>
            </div>
            <div
              v-if="newCarInfo.internalImages.length > 1"
              @click="isExterior = false"
              :class="
                isExterior
                  ? 'image-button-wrapper-interior isBehind'
                  : 'image-button-wrapper-interior '
              "
            >
              <img src="@/assets/assento.svg" />
              <span>Interior</span>
            </div>
            <ThreeSixtyImage
              v-if="isExterior && newCarInfo.allImages.length > 35"
              :imgs="newCarInfo.allImages"
              ref="threesixty"
            />
            <img
              v-else-if="isExterior && newCarInfo.allImages.length < 35"
              :src="newCarInfo.allImages[0].url"
            />
            <img
              v-else-if="!isExterior && interiorImage"
              :src="interiorImage"
              :key="update"
            />
          </div>
          <Swiper
            v-if="isExterior"
            :data="newCarInfo.alternativeImage"
            @alternativeClick="alternativeClick"
          />
          <div
            v-else-if="newCarInfo.internalImages.length > 1 && !isExterior"
            class="interior-swiper"
          >
            <Swiper
              isUsed
              :data="newCarInfo.internalImages"
              @alternativeClick="alternativeInteriorClick"
            />
          </div>
          <div class="hero-color-variants">
            <ColorVariants
              :data="newCarInfo.colorVariants"
              @variantClicked="variantClick"
            />
          </div>
        </div>
        <div class="right-slot">
          <div class="upper-section">
            <div class="hero-economic">
              <div>
                <div class="hero-heading-row">
                  <h1 class="hero-heading">
                    <span>{{ newCarInfo.title }}</span>
                  </h1>
                  <div class="elm-desktop">
                    <h2 class="hero-interested">
                      <span>{{ newCarInfo.viewers }} interessados</span
                      ><span> nas últimas 24h</span>
                    </h2>
                  </div>
                </div>
                <h2 class="hero-sub-heading">
                  <span>{{ newCarInfo.version }}</span>
                </h2>
              </div>
              <div class="color-wrapper">
                <p v-if="newCarInfo.color" class="hero-color in-new">
                  <span class="red-bullet"> &bull; </span
                  ><span
                    ><span class="color-label">Cor Exterior:</span>
                    {{ newCarInfo.color.split("/")[0] }}</span
                  ><span
                    class="red-bullet"
                    v-if="newCarInfo.color.split('/').length > 1"
                  >
                    &bull; </span
                  ><span>{{ newCarInfo.color.split("/")[1] }}</span>

                  <span class="color-int" v-if="colorInt"
                    ><span class="red-bullet">&bull; </span
                    ><span class="color-label">Cor Interior:</span>
                    {{ colorInt }}</span
                  >
                </p>
                <div
                  class="order-label"
                  id="order"
                  tabindex="0"
                  v-if="newCarInfo.interiorColors.length > 0"
                  @focusout="colorToggleState = false"
                  @click="colorToggleState = !colorToggleState"
                >
                  {{ selectedOrder }}<Arrow />
                  <div
                    :class="
                      colorToggleState ? 'option-panel' : 'option-panel close'
                    "
                  >
                    <div
                      class="custom-option"
                      v-for="(color, i) in interiorColorArray"
                      @click="variantClick(color.vin)"
                      :key="i"
                    >
                      {{ color.interior }}
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div class="hero-price-row">
                <div class="hero-price-details">
                  <div class="legal-row">
                    <div class="info-legal">
                      <img :src="require('@/assets/info.svg')" />
                      <p class="info-text" v-html="newCarInfo.legalInfo"></p>
                    </div>
                    <div class="hero-price-taeg">
                      Em {{ newCarInfo.financialProduct }}
                    </div>
                  </div>
                  <p class="hero-price-month">
                    {{ newCarInfo.formattedFee }}
                  </p>
                  <p class="hero-price-taeg">TAEG: {{ newCarInfo.taeg }}</p>
                </div>
                <div class="hero-price-details">
                  <div class="range-price-full-pvp">PVP</div>
                  <div class="legal-row">
                    <div class="info-legal">
                      <img :src="require('@/assets/info.svg')" />
                      <p
                        class="info-text-right"
                        v-html="newCarInfo.legalInfo"
                      ></p>
                    </div>
                    <div class="hero-price-full">
                      {{ newCarInfo.formattedPrice }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-price-info">
                <p class="hero-price-label">
                  Entrada inicial:
                  <span class="hero-price-value">{{
                    newCarInfo.initEntrance
                  }}</span>
                </p>
                <p class="hero-price-label">
                  Prazo:
                  <span class="hero-price-value"
                    >{{ newCarInfo.nrPayments }} meses</span
                  >
                </p>
                <p class="hero-price-label">
                  Montante financiado:
                  <span class="hero-price-value">{{
                    newCarInfo.capitalToFinance
                  }}</span>
                </p>
                <p
                  class="hero-price-label"
                  v-if="newCarInfo.idFinancialProduct == 1"
                >
                  VFMG:
                  <span class="hero-price-value">{{ newCarInfo.vfmg }}</span>
                </p>
              </div>
              <div v-if="legal">
                <p class="hero-title">Notas Legais</p>
                <p class="legal" v-html="newCarInfo.legalInfo"></p>
              </div>
            </div>
            <div class="elm-mobile">
              <h2 class="hero-interested">
                <span>{{ newCarInfo.viewers }} interessados</span
                ><span> nas últimas 24h</span>
              </h2>
            </div>
            <ButtonsWrapper
              :data="newCarCta"
              btnClass="four-btn "
              wrapperClass="four-button hero-btn row  "
              @action="action($event)"
            />
          </div>
        </div>

        <div class="lower-section">
          <div class="hero-accordion-container">
            <div class="hero-accordion-item">
              <button
                :class="toggleSpec ? 'is-clicked accordion' : 'accordion'"
                @click="toggle(0)"
              >
                Especificações
                <Arrow class="arrow" :class="{ clicked: toggleSpec }" />
              </button>
              <Transition name="panel">
                <div class="panel" v-if="toggleSpec">
                  <SpecList :data="newCarInfo.specifications" />
                </div>
              </Transition>
            </div>
            <div class="hero-accordion-item">
              <button
                :class="toggleEquip ? 'is-clicked accordion' : 'accordion'"
                @click="toggle(2)"
              >
                Equipamentos
                <Arrow class="arrow" :class="{ clicked: toggleEquip }" />
              </button>
              <Transition name="panel">
                <div class="panel" v-if="toggleEquip">
                  <EquipList :data="newCarInfo.equipment" />
                </div>
              </Transition>
            </div>
            <div class="hero-accordion-item">
              <button
                :class="toggleDimen ? 'is-clicked accordion' : 'accordion'"
                @click="toggle(1)"
              >
                Dimensões
                <Arrow class="arrow" :class="{ clicked: toggleDimen }" />
              </button>
              <Transition name="panel">
                <div class="panel dim" v-if="toggleDimen">
                  <DimensionImage
                    :imageUrl="newCarInfo.allImages[9]"
                    :dimensions="newCarInfo.dimensions"
                    :frontView="false"
                  />
                  <DimensionImage
                    :imageUrl="newCarInfo.allImages[0]"
                    :dimensions="newCarInfo.dimensions"
                    :frontView="true"
                  />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Section v-if="toggleOSDisplay" id="onlineSelling">
      <div class="title-area">
        <p class="form-title">
          Reserve Online o seu novo Toyota {{ newCarInfo.model }}
        </p>
        <p class="simulator-text" v-html="onlineBookingText"></p>
      </div>
      <FormOS
        :imageUrl="
          newCarInfo.allImages.length > 35
            ? newCarInfo.allImages[3].url
            : newCarInfo.allImages[0].url
        "
        class="form-fw"
        :data="formOS"
        :car="{
          model: newCarInfo.model,
          desc: newCarInfo.version,
          fee: newCarInfo.formattedFee,
          taeg: newCarInfo.taeg,
          price: newCarInfo.formattedPrice,
          initEntrance: newCarInfo.initEntrance,
          nrPayments: newCarInfo.nrPayments,
          capitalToFinance: newCarInfo.capitalToFinance,
          legalInfo: newCarInfo.legalInfo,
        }"
        id="online-selling"
        @submit="formOSSubmit"
        form="onlineSelling"
      />
      <div class="legal-money">
        <span
          >Realize o seu pagamento de forma segura através dos seguintes métodos
          de pagamento:
        </span>
        <img src="../assets/cc-mb-square.png" alt="" />
      </div>
    </Section>
    <Section v-if="toggleSimulatorDisplay" id="simulator">
      <div class="align-left">
        <p class="form-title">
          Simulador de Financiamento {{ isLexus ? "Lexus" : "Toyota" }}
        </p>
        <p class="simulator-text" v-html="simulatorText"></p>
      </div>
      <Simulator
        :finalPrice="finalPrice"
        :vin="newCarInfo.vin"
        :initValues="{ ...newCarInfo }"
        :creditTypes="newCarInfo.creditTypes"
        @action="checkValues"
      />
    </Section>

    <Section v-if="toggleTestDriveDisplay" id="testDrive">
      <div class="title-area">
        <p class="form-title">
          Agende de forma online o Test-Drive do seu novo {{ newCarInfo.model }}
        </p>
        <p class="simulator-text" v-html="testDriveText"></p>
      </div>
      <FormTS
        class="form-fw"
        :data="formTS"
        carType="novo"
        :model="newCarInfo.model"
      />
    </Section>
    <CTA isRanged @callAction="ctaAction" />
  </div>
</template>

<script>
import Simulator from "@/components/Simulator/Simulator.vue";
import CTA from "@/components/CTA/CTA.vue";
import FormOS from "@/components/Forms/FormOS.vue";
import Section from "@/components/Section/Section.vue";
import FormConfiguration from "@/data/FormsDev.json";
import EquipList from "@/components/RangeList/EquipList";
import SpecList from "@/components/RangeList/SpecList";
import DimensionImage from "@/components/Images/DimensionImage";
import Arrow from "@/assets/OpenArrow.vue";
import ButtonsWrapper from "@/components/Buttons/ButtonsWrapper.vue";
import Swiper from "@/components/Swiper/Swiper.vue";
import ColorVariants from "@/components/RangeList/ColorVariants.vue";
import ThreeSixtyImage from "@/components/Images/ThreeSixtyImage";
import Heart from "@/assets/Heart.vue";
import { mapGetters } from "vuex";

import { formatPriceNumber } from "@/functions/general.js";

import { formOsSubmit } from "@/services/functions.js";
import FormTS from "../components/Forms/FormTS.vue";

export default {
  name: "RangeSingle",
  metaInfo() {},
  components: {
    Simulator,
    Section,
    FormOS,
    FormTS,
    Arrow,
    EquipList,
    SpecList,
    DimensionImage,
    ButtonsWrapper,
    ColorVariants,
    CTA,
    Swiper,
    ThreeSixtyImage,
    Heart,
  },
  data() {
    return {
      newCarInfo: [],
      legal: false,
      isExterior: true,
      isFavorite: false,

      toggleOSDisplay: false,
      toggleSimulatorDisplay: true,
      toggleTestDriveDisplay: false,
      formOS: FormConfiguration.onlineSelling,
      formTS: {},
      usedCarInfoForm: [],
      colorToggleState: false,

      toggleEquip: false,
      toggleDimen: false,
      toggleSpec: true,
      dealers: [],
      isLoaded: false,
      interiorImage: "",
      update: 0,
    };
  },
  computed: {
    newCarCta() {
      return [
        {
          buttonLink: "#testDrive",
          buttonLabel: "Marcar Test Drive",
          btnClass: "hero-btn",
          anchor: true,
        },
        {
          buttonLink: "#simulator",
          buttonLabel: "Simulador de Financiamento",
          btnClass: "primary-btn",
          anchor: true,
        },
        ...(this.newCarInfo.isAvailable
          ? [
              {
                buttonLink: "#onlineSelling",
                buttonLabel: "Reserva Online",
                btnClass: "hero-btn",
                anchor: true,
              },
            ]
          : []),
        /*         {
          buttonLink: "/retake/1",
          buttonLabel: "Calcular Retoma",
          btnClass: "secondary-btn",
          anchor: false,
        }, */
      ];
    },
    onlineBookingText() {
      return this.replaceBOVariable(
        this.$store.state.onlineBookingText,
        "{#modelCar}",
        this.newCarInfo.model
      );
    },
    testDriveText() {
      return this.replaceBOVariable(
        this.$store.state.testDriveText,
        "{#modelCar}",
        this.newCarInfo.model
      );
    },
    finalPrice() {
      return Number(this.newCarInfo.price);
    },
    selectedOrder() {
      return this.selOrder;
    },
    simulatorText() {
      return this.replaceBOVariable(
        this.$store.state.simulatorText,
        "{#modelCar}",
        this.newCarInfo.model
      );
    },
    dealerDetail() {
      return this.$store.state.allInstallations;
    },
    colorInt() {
      let color = "";
      color = this.newCarInfo.specifications.specifications.find(
        (el) => el.title == "Interior"
      ).description;
      return color;
    },
    interiorColorArray() {
      return [
        { interior: "Alterar Interior", vin: "" },
        ...this.newCarInfo.interiorColors,
      ];
    },
    selOrder() {
      return this.newCarInfo.interiorColors.length > 0
        ? this.interiorColorArray[0].interior
        : "";
    },
    ...mapGetters("user", {
      user: "getUserData",
    }),
    isLexus() {
      return this.$store.state.isLexus;
    },
  },
  beforeCreate() {
    this.$store
      .dispatch("getCar", {
        vin: this.$route.params.vin,
        sessionId: this.$store.state.user.sessionId
          ? this.$store.state.user.sessionId
          : "",
      })
      .then(() => {
        this.newCarInfo = this.$store.getters.getMappedNewCar;
        this.interiorImage = this.newCarInfo.internalImages[0]
          ? this.newCarInfo.internalImages[0].url
          : [];

        this.mapSpecifications();
      })
      .catch(() => {
        this.$router.push({ name: "not-found" });
      });
    this.$store.dispatch("getFilteredInstallations", "N");
  },
  methods: {
    formOsSubmit,
    formatPriceNumber,
    mapSpecifications() {
      this.newCarInfo.specifications.specifications.forEach((ele) => {
        switch (ele.title) {
          case "version":
            ele.title = "Versão";
            break;
          case "power":
            ele.title = "Potência";
            break;
          case "exterior":
            ele.title = "Cor Exterior";
            break;
          case "interior":
            ele.title = "Interior";
            break;
          case "transmission":
            ele.title = "Transmissão";
            break;
          case "consumption":
            ele.title = "Consumo";
            break;
          default:
        }
      });

      this.formTS = FormConfiguration.testDriveNovos;
      this.$store.state.allInstallations.forEach((element) => {
        if (element.designation != undefined || element.objectId != undefined) {
          this.dealers.push({
            label: element.designation + " - " + element.postalCodeDesig,
            value: element.objectId,
          });
        }
      });

      this.formTS.fields.find((el) => el.name == "oidDealer")["options"] =
        this.dealers;
      this.isLoaded = true;
      if (
        this.$route.hash == "#onlineSelling" &&
        this.$store.state.user.loginToken
      ) {
        this.toggleOSDisplay = true;
        setTimeout(() => {
          let element = document.querySelector(this.$route.hash);
          element && element.scrollIntoView();
        }, 500);
      }
      this.$store.commit("SET_LOADING", false);
    },
    action(event) {
      switch (event) {
        case 0:
          this.toggleTestDriveDisplay = true;
          break;
        case 1:
          this.toggleSimulatorDisplay = true;
          break;
        case 2:
          if (this.$store.state.user.loginToken) {
            this.toggleOSDisplay = true;
          } else {
            this.$root.$emit("login");
          }
          break;
        default:
      }
    },
    ctaAction(action) {
      let element;
      switch (action) {
        case "1":
          this.toggleTestDriveDisplay = true;
          setTimeout(() => {
            element = document.querySelector("#testDrive");
            element && element.scrollIntoView();
          }, 100);

          break;
        case "2":
          if (this.$store.state.user.loginToken) {
            this.toggleOSDisplay = true;
            setTimeout(() => {
              element = document.querySelector("#onlineSelling");
              element && element.scrollIntoView();
            }, 100);
          } else {
            this.$root.$emit("login");
          }
          break;
        case "-1":
          this.$router.push("/instalacoes");
          break;
      }
    },
    toggle(index) {
      switch (index) {
        case 0:
          this.toggleSpec = !this.toggleSpec;
          this.$forceUpdate();
          break;
        case 1:
          this.toggleDimen = !this.toggleDimen;
          this.$forceUpdate();
          break;
        case 2:
          this.toggleEquip = !this.toggleEquip;
          this.$forceUpdate();
          break;
      }
    },
    toogleFavorite() {
      if (this.user.id) {
        this.$store.dispatch("user/toggleFavorite", {
          vin: this.$route.params.vin,
          fid: this.newCarInfo.fid,
        });
        this.isFavorite = !this.isFavorite;
      } else {
        this.$root.$emit("login");
      }
    },
    variantClick(variant) {
      if (variant) {
        this.isLoaded = false;
        this.$store.commit("SET_LOADING", true);
        this.$store
          .dispatch("getCar", {
            vin: variant,
            sessionId: this.$store.state.user.sessionId
              ? this.$store.state.user.sessionId
              : "",
          })
          .then(() => {
            this.colorToggleState = false;
            this.newCarInfo = this.$store.getters.getMappedNewCar;
            this.interiorImage = this.newCarInfo.internalImages[0]
              ? this.newCarInfo.internalImages[0].url
              : [];
            this.mapSpecifications();
          });
      }
    },
    showLegal() {
      this.legal = !this.legal;
    },
    alternativeClick(url) {
      this.$refs.threesixty.select(url);
    },
    alternativeInteriorClick(img) {
      this.interiorImage = img.url;
    },
    checkValues() {
      this.simulatorHasValues = true;
    },
    replaceBOVariable(text, variable, info) {
      return text.replaceAll(variable, info);
    },
    formOSSubmit(data, paymentMethod) {
      let car = this.$store.state.selectedCar;

      let body = {
        delivery: "CUSTOM",
        fid: car.carDetailCard.financeId,
        reservationPayload: {
          dealershipId: car.companyId,
          deliveryDealer: car.dealerCode,
          endReserveDate: new Date(),
          finance: {
            capitalFinance: car.carDetailFinance.capitalFinance,
            creditType: 5,
            depositValue: car.carDetailFinance.depositValue,
            dtEndOfCampaign:
              car.carDetailCard.tooltipDescription.dtEndOfCampaign,
            financedValue: car.carDetailFinance.capitalFinance,
            initialExpenses: car.carDetailFinance.initialExpenses,
            lastFee: car.carDetailCard.tooltipDescription.lastFee,
            monthlyValue: car.carDetailFinance.monthlyPayment,
            mtic: car.carDetailFinance.ntic,
            nbrMonths: car.carDetailFinance.months,
            pricePvp: car.carDetailFinance.pvp,
            residualValue: car.carDetailFinance.residualValue,
            taeg: car.carDetailFinance.taeg,
            tan: car.carDetailFinance.tan,
          },
          pricePvp: car.carDetailCard.pricePvp,
          receipt: {
            address: "",
            location: "",
            nif: "",
            zipCode: "",
          },
          reserveDate: new Date(),
          reserveType: 1,
        },
        vin: this.newCarInfo.vin,
        //financeId: car.carDetailCard.financeId,
        paymentDetails: {
          bookingNumber: "",
          buyerCar: {
            model: this.newCarInfo.model,
            nrPlate: car.carDetailCard.licensePlate,
            purchaseType: "N",
            version: this.newCarInfo.version,
            vin: this.newCarInfo.vin,
          },
          client: data,
          district: "",
          idCampaign: this.$store.state.isLexus ? 63 : 62,
          oidDealer: "SC00020073",
          paymentMethod: paymentMethod,
          price: car.carDetailCard.pricePvp,
          receiveMarketingCommunication: "S",
          redirNOK: this.formOS.fields.find((el) => el.name == "redirPath")
            .value,
          redirNoCampaign: this.formOS.fields.find(
            (el) => el.name == "redirPath"
          ).value,
          redirOK:
            paymentMethod == "mbreference"
              ? location.origin + "/reserva"
              : this.formOS.fields.find((el) => el.name == "redirPath").value,
          fwCompleteTransactionEndpoint: location.origin + "/confirmacao",
          fwErrorVisaPage: location.origin + "/erro",
          fwOkVisaPage: location.origin + "/confirmacao",
        },
      };
      this.formOsSubmit(body, this.$store.state.user.loginToken).then((res) => {
        if (paymentMethod != "mbreference") {
          window.open(
            res.data.redirect_url,
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
          this.$router.push("/reserva");
        } else {
          window.location.href =
            res.data.redirect_url + `&model=${this.newCarInfo.version}`;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "../components/RangeList/Range.scss";
@import "../components/Heroes/Heroes.scss";
</style>
